import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Components from '@/components/globals'
import Filters from '@/filters'
import VueDismiss from '@/mixins/VueDismiss'
import TailwindMixin from '@/mixins/TailwindMixin'
import i18n from './locales'
import { validationMixin } from 'vuelidate/src'
import BaseNotify from '@/plugins/BaseNotifications/BaseNotify'
import BaseConfirmation from '@/plugins/BaseConfirmation/BaseConfirmation'
import BaseDocumentPreview from '@/plugins/BaseDocumentPreview/BaseDocumentPreview'
import VueSignaturePad from 'vue-signature-pad'
import VueEasyLightbox from 'vue-easy-lightbox'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import FloatingVue from 'floating-vue'
import * as Sentry from '@sentry/vue'
import OneSignalWrapper from './OneSignalWrapper'

import 'vue-js-modal/dist/styles.css'
import '@/assets/css/tailwind.css'
import '@spatie/media-library-pro-styles/dist/styles.css'
import 'floating-vue/dist/style.css'

Vue.config.productionTip = false

Object.keys(Components).forEach((key) => {
    Vue.component(key, Components[key])
})

Object.keys(Filters).forEach((key) => {
    Vue.filter(key, Filters[key])
})

Vue.mixin(TailwindMixin)
Vue.mixin(VueDismiss)
Vue.mixin(validationMixin)

Vue.use(BaseNotify, { store })
Vue.use(BaseConfirmation)
Vue.use(BaseDocumentPreview)
Vue.use(VueSignaturePad)
Vue.use(VueEasyLightbox)
Vue.use(VModal)
Vue.use(FloatingVue)
OneSignalWrapper.init(Vue)

if (import.meta.env.PROD) {
    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_APP_SENTRY_DNS_URL,
        integrations: [
            new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost', // Matches local development environment
                    /^https:\/\/apidev\.myabseil\.com/, // Matches API calls for the development environment
                    /^https:\/\/api\.myabseil\.com/ // Matches API calls for the production environment
                ]
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

new Vue({
    router,
    i18n,
    store,
    beforeMount () {
        window.OneSignalDeferred = window.OneSignalDeferred || []

        this.$OneSignal.init({
            appId: import.meta.env.VITE_APP_ONESIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
                enable: true
            },
            serviceWorkerParam: { scope: '/push/onesignal/' },
            serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
            promptOptions: {
                slidedown: {
                    prompts: [
                        {
                            type: 'push', // current types are "push" & "category"
                            autoPrompt: false,
                            text: {
                                /* limited to 90 characters */
                                actionMessage: this.$t('global.push-subscribe-text'),
                                /* acceptButton limited to 15 characters */
                                acceptButton: this.$t('global.push-subscribe-button'),
                                /* cancelButton limited to 15 characters */
                                cancelButton: this.$t('global.no')
                            }

                        }
                    ]
                }
            }
        })
    },
    render: (h) => h(App)
}).$mount('#app')
