import OneSignalVue from 'onesignal-vue'

class OneSignalWrapper {
    constructor () {
        this.isProd = import.meta.env.PROD
        this.instance = null
    }

    init (Vue) {
        if (this.isProd) {
            Vue.use(OneSignalVue)
            this.instance = Vue.prototype.$OneSignal
        } else {
            // Create mock implementation for development
            this.instance = {
                login: () => console.debug('OneSignal.login called (dev mode)'),
                logout: () => console.debug('OneSignal.logout called (dev mode)'),
                User: {
                    PushSubscription: {
                        addEventListener: () => console.debug('PushSubscription.addEventListener called (dev mode)')
                    }
                },
                Notifications: {
                    isPushSupported: () => false,
                    permission: false,
                    requestPermission: () => console.debug('Notifications.requestPermission called (dev mode)'),
                    addEventListener: () => console.debug('Notifications.addEventListener called (dev mode)'),
                    Slidedown: {
                        promptPush: () => console.debug('Slidedown.promptPush called (dev mode)')
                    }
                },
                init: () => console.debug('OneSignal.init called (dev mode)')
            }
        }

        // Replace Vue's OneSignal instance with our wrapper
        Vue.prototype.$OneSignal = this.instance
    }

    getInstance () {
        return this.instance
    }
}

export default new OneSignalWrapper()
