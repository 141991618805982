import AxiosConfig from '@/api/config'
import { handleError } from '@/utils/errorHandler'

/**
 * Makes an API call using the configured Axios instance.
 * @param {string} method - The HTTP method to use (get, post, put, delete, etc.)
 * @param {string} url - The API endpoint URL
 * @param {Object} [data=null] - The request payload (for POST, PUT, etc.)
 * @param {Object} [params=null] - The URL parameters
 * @param {Object} [options={}] - Additional Axios request options
 * @returns {Promise<any>} The response data
 * @throws {Error} If the API call fails
 */
export async function apiCall (method, url, data = null, params = null, options = {}) {
    try {
        const config = {
            method,
            url,
            ...options
        }

        if (data) config.data = data
        if (params) config.params = params

        const response = await AxiosConfig(config)

        return response.data
    } catch (error) {
        handleError(error, `API call to ${url}`)
        throw error
    }
}

/**
 * Convenience methods for common HTTP verbs
 */
export const api = {
    get: (url, params, options) => apiCall('get', url, null, params, options),
    post: (url, data, options) => apiCall('post', url, data, null, options),
    put: (url, data, options) => apiCall('put', url, data, null, options),
    delete: (url, data, options) => apiCall('delete', url, data, null, options),
    patch: (url, data, options) => apiCall('patch', url, data, null, options)
}
